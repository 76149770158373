import {
  AppShell,
  Box,
  ColorSchemeScript,
  Group,
  Image,
  MantineProvider,
} from "@mantine/core";
import { Links, Meta } from "@remix-run/react";
import { headerHeight } from "~/layouts/_app";
import { customTheme } from "~/styles/theme";

export default function RootErrorBoundary() {
  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <Meta />
        <Links />
        <ColorSchemeScript />
      </head>
      <body id="app">
        <MantineProvider theme={customTheme}>
          <AppShell className="w-full" header={{ height: headerHeight }}>
            <Box>
              <AppShell.Header className="border-none h-[60px] px-4 bg-secondary-700 text-white">
                <Group justify="space-between" h="100%">
                  <Group>
                    <Group>
                      <a href="/">
                        <Image
                          alt="Podsie logo"
                          src="/logo512.png"
                          className="max-w-12"
                        />
                      </a>
                    </Group>
                  </Group>
                </Group>
              </AppShell.Header>
            </Box>
            <AppShell.Main className="bg-gray-100 w-full flex flex-col">
              <Box className="p-5">
                We&apos;re really sorry, but something went wrong! We&apos;ve
                received an alert about this issue and will fix it ASAP. Please
                refresh your page, thank you.
              </Box>
            </AppShell.Main>
          </AppShell>
        </MantineProvider>
      </body>
    </html>
  );
}
